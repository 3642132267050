import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withPrefix, graphql } from "gatsby";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Home from "../containers/Home";

const styles = theme => ({
  ...theme.typography,
  container: {
    margin: "auto",
    position: "absolute",
    left: "0",
    bottom: "0",
    right: "0",
    [theme.breakpoints.down("sm")]: {
      padding: "10px"
    },
    [theme.breakpoints.up("sm")]: {
      padding: "20px 20%"
    }
  },
  text: {
    [theme.breakpoints.down("xs")]: {
      fontSize: '25px !important',
      fontWeight: 700,
      color: '#ffffff',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '55px',
      letterSpacing: 'normal'
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: '30px',
      fontWeight: 700,
      color: '#ffffff',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '62px',
      letterSpacing: 'normal'
    },
    fontSize: "40px !important",
    color: "white",
    padding: "0",
    textAlign: "center",
    textTransform: "uppercase"
  },
  paragraph: {
    [theme.breakpoints.down("sm")]: {
      ...theme.typography.paragraph
    },
    [theme.breakpoints.up("sm")]: {
      ...theme.typography.paragraph
    },
    fontSize: "20px !important",
    textAlign: "center",
    color: "rgba(221, 221, 221, 255) !important",
    padding: "0"
  },
  textMultiline: {
    display: "inline",
    padding: "0.85rem 0.19rem",
    boxDecorationBreak: "clone",
    WebkitBoxDecorationBreak: "clone"
  },
  space: {
    marginTop: "100px"
  },
  banner: {
    position: "relative",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "inherit",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "520px"
    },
    [theme.breakpoints.up("sm")]: {
      height: "620px"
    }
  }
});

class IndexPage extends React.Component {
  render() {
    const { data, classes } = this.props;
    const { frontmatter } = data.markdownRemark;

    return (
      <React.Fragment>
        <div className={classes.space} />
        <div
          className={classes.banner}
          style={{
            backgroundImage: `url(${withPrefix(frontmatter.image)})`
          }}
        >
          <Grid
            className={classes.container}
            direction="row"
            alignItems="center"
            container
          >
            <Grid item xs={12} md={12} lg={12}>
              <Typography className={classes.text}>
                <span className={classes.textMultiline}>
                  {frontmatter.title}
                </span>
              </Typography>
              <Typography className={classes.paragraph}>
                <span className={classes.textMultiline}>
                  {frontmatter.heading}
                </span>
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Home />
      </React.Fragment>
    );
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export const pageQuery = graphql`
  query HomePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        image
        firstSection {
          heading
          description
          image
          linkText
        }
        secondSection {
          heading
          description
          image
        }
        testimonials {
          author
          quote
        }
      }
    }
  }
`;

export default withStyles(styles)(IndexPage);
