import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { withPrefix } from "gatsby";
import LetsTalk from "../components/LetsTalk";
import Layout from '../components/layout';
import { navigate } from '@reach/router';

const styles = theme => ({
  root: {
    textAlign: "center"
  },
  section: {
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing.unit * 2
    },
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing.unit * 10
    }
  },
  heading: {
    ...theme.typography.heading,
    fontSize: "35pt",
    textTransform: "uppercase",
    fontWeight: 900,
  },
  yellow: {
    margin: "auto",
    width: "31px",
    border: "2.5px solid #fbc011",
    marginBottom: "25px"
  },
  paragraph: {
    ...theme.typography.paragraph,
    lineHeight: "1.8",
    listStyleType: "disc",
    color: "#777777",
    fontSize: "16px",
    fontStyle: "normal",
    fontFamily: "Lato",
  },
  space: {
    marginTop: "53px",
  },
  space2: {
    marginTop: "36px",
  },
  space3: {
    marginTop: "24px",
    color: "#777"
  },
  logo: {
    width: "200px",
    paddingLeft: "35px",
    paddingRight: "35px"
  },
  section2: {
    backgroundColor: "#fbfbfb",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing.unit * 2,
    },
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing.unit * 10
    }
  },
  section3: {
    backgroundColor: "#4f8dff",
  },
  middlePara: {
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.up("sm")]: {
      width: "40%"
    }
  },
  bold: {
    fontWeight: "bold",
    color: "#222222"
  },
  subheading: {
    ...theme.typography.subheading,
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, 10%)",
    width: "100%",
    textTransform: "uppercase",
    color: "#fff",
    fontSize: "40px",
    fontStyle: "normal",
    fontFamily: "Lato",
    fontWeight: 900
  },
  geek: {
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundImage: `url(${withPrefix("img/geek.png")})`,
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "40px",
      width: "350px",
      height: "350px"
    },
    [theme.breakpoints.up("md")]: {
      width: "425px",
      height: "370px"
    },
    [theme.breakpoints.up("lg")]: {
      width: "425px",
      height: "370px"
    }
  },
  geekPara: {
    marginTop: "70px",
    ...theme.typography.paragraph,
    color: "white !important",
    textAlign: "justify",
    padding: "10px",
    fontSize: '16px'
  },
  input: {
    width: "100%",
    height: "43px",
    backgroundColor: "#f8f8f8",
    padding: "0 10px",
    border: "0"
  },
  textarea: {
    height: "124px"
  },
  button: {
    ...theme.typography.paragraph,
    fontWeight: "bold",
    backgroundColor: "#4794ff",
    margin: "auto",
    padding: "15px",
    color: "white",
    lineHeight: '1.5',
    fontSize: '16px',
    letterSpacing: 'normal',
    textTransform: "uppercase",
    borderLeft: "4px solid #1e58a8",
    [theme.breakpoints.down("sm")]: {
      margin: "5px auto 25px auto",
    },
  },
  geekButton: {
    backgroundColor: "white",
    color: "#4794ff",
    margin: "0px"
  },
  left: {
    textAlign: "left",
    paddingLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20px",
      color: "red"
    }
  },
  leftPos: {
    textAlign: "left"
  },
  image: {
    cursor: "pointer"
  },
});

const Home = props => {
  const { classes, location } = props;

  return (
    <Layout location={location}>
      <div className={classes.root}>
        <div className={classes.section}>
          <div className={classes.yellow} />
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.heading}>Who we are</Typography>
              <Typography
                className={classNames(classes.paragraph, classes.space)}
              >
                Through our engineering driven approach, we help companies
                leverage the power of digital technology to achieve real business
                outcomes and lead in disruptive times. In over a decade of our
                existence, Primoko has emerged as a leading and trusted technology
                partner for businesses across various industries.
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div className={classNames(classes.section, classes.space)}>
          <div className={classes.yellow} />
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.heading}>
                And We've Big Brand Experience
              </Typography>
              <Typography
                className={classNames(classes.paragraph, classes.space2)}
              >
                Our esteemed customers who trust us to build their applications.
              </Typography>
              <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.space2}
              >
                <Grid item>
                  <img
                    className={classes.logo}
                    src={withPrefix("img/time-warner.jpg")}
                  />
                </Grid>
                <Grid item>
                  <img
                    className={classes.logo}
                    src={withPrefix("img/rutgers-logo.png")}
                  />
                </Grid>
                <Grid item>
                  <img
                    className={classes.logo}
                    src={withPrefix("img/orchestra-tech.png")}
                  />
                </Grid>
                <Grid item>
                  <img
                    className={classes.logo}
                    src={withPrefix("img/barneys.png")}
                  />
                </Grid>
                <Grid item>
                  <img
                    className={classes.logo}
                    src={withPrefix("img/bofa.png")}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className={classNames(classes.section2)}>
          <div className={classes.yellow} />
          <Grid container spacing={40}>
            <Grid item xs={12}>
              <Typography className={classes.heading}>
                What We Can Do For You
              </Typography>
              <Typography
                className={classNames(
                  classes.paragraph,
                  classes.space2,
                  classes.middlePara
                )}
              >
                Our expertise ranges from software application development and
                maintenance, to migration and reengineering to testing services.
                Whether it’s an enterprise wide custom application, mobile app, a
                complex e-commerce platform, we can build it and we can build it
                better! Our services can be broadly classified as follows:
              </Typography>
              <Grid
                container
                justify="space-around"
                alignItems="center"
                className={classes.space2}
              >
                <Grid item sm={2} onClick={() => navigate("/services/web")}>
                  <img
                    className={classes.image}
                    src={withPrefix("img/web.png")}
                    width="55px"
                    height="55px"
                  />
                  <Typography
                    className={classNames(
                      classes.paragraph,
                      classes.bold,
                      classes.space3
                    )}
                  >
                    WEB
                  </Typography>
                </Grid>
                <Grid
                  item
                  sm={2}
                  onClick={() => navigate("/services/mobile")}
                >
                  <img
                    className={classes.image}
                    src={withPrefix("img/mobile.png")}
                    width="33px"
                    height="61px"
                  />
                  <Typography
                    className={classNames(
                      classes.paragraph,
                      classes.bold,
                      classes.space3
                    )}
                  >
                    MOBILE
                  </Typography>
                </Grid>
                <Grid
                  item
                  sm={2}
                  onClick={() => navigate("/services/devops")}
                >
                  <img
                    className={classes.image}
                    src={withPrefix("img/devops.png")}
                    width="71px"
                    height="63px"
                  />
                  <Typography
                    className={classNames(
                      classes.paragraph,
                      classes.bold,
                      classes.space3
                    )}
                  >
                    DEVOPS
                  </Typography>
                </Grid>
                <Grid
                  item
                  sm={2}
                  onClick={() => navigate("/services/salesforce")}
                >
                  <img
                    className={classes.image}
                    src={withPrefix("img/salesforce.png")}
                    width="104px"
                    height="60px"
                  />
                  <Typography
                    className={classNames(
                      classes.paragraph,
                      classes.bold,
                      classes.space3
                    )}
                  >
                    SALESFORCE
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <button
              className={classes.button}
              onClick={() => navigate("/services")}
            >
              Read More
            </button>
          </Grid>
        </div>
        <div className={classes.section3}>
          <Grid container justify="space-around">
            <Grid item xs={12}>
              <Typography className={classNames(classes.subheading)}>
                We Speak Geek
              </Typography>
            </Grid>
  
            <Grid item xs={12} sm={3}>
              <div className={classes.geek} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography className={classNames(classes.geekPara)}>
                We’re a software engineering firm with only software engineers.
                Imagine that! Yes, we do handle the sales, marketing, customer
                relations and other non-engineering functions, but everyone in the
                company comes from a technical background. We talk, joke and even
                make geeky memes of our programming experience This is why your
                engineers will love working with us.
              </Typography>
              <div className={classNames(classes.left, classes.space2)}>
                <button
                  className={classNames(classes.button, classes.geekButton)}
                  onClick={() => navigate("/the-primoko-difference")}
                >
                  Read More
                </button>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={classes.section}>
          <div className={classes.yellow} />
          <Grid container justify="center">
            <Grid item xs={6}>
              <Typography className={classes.heading}>GET IN TOUCH</Typography>
            </Grid>
          </Grid>
          <Grid container justify="center" className={classes.space}>
            <Grid item xs={12} sm={4}>
              <img
                className={classes.image}
                src={withPrefix("img/call.png")}
                width="32px"
                height="29px"
              />
              <Typography
                className={classNames(classes.paragraph, classes.space3)}
              >
                Tel:
              </Typography>
              <Typography
                className={classNames(classes.paragraph, classes.space3)}
              >
                +1 (609) 216-7354
              </Typography>
              <img
                className={classNames(classes.image, classes.space2)}
                src={withPrefix("img/mail.png")}
                width="32px"
                height="22px"
              />
              <Typography
                className={classNames(classes.paragraph, classes.space3)}
              >
                Email:
              </Typography>
              <Typography
                className={classNames(classes.paragraph, classes.space3)}
              >
                hello@primoko.com
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.leftPos}>
              <LetsTalk />
            </Grid>
          </Grid>
        </div>
      </div>
    </Layout>
  );
};

export default withStyles(styles)(Home);
